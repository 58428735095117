<template>
    <div>
      <!-- <v-btn v-if="checkedSensors && !sensors.length && !isReadOnly" to="/add">Add New Sensor</v-btn> -->
      <v-card>
        <v-card-title>
          <span class="mr-3">Sensors</span><v-btn v-if="!isReadOnly && !sensors.length && checkedSensors && isCustomer" to="/add">Add Sensor</v-btn>
          <!-- <v-flex xs4 sm2 v-if="!isCustomer && !isMDX && !isKDX && !isTDX && !isReadOnly">
            <v-select v-model="deviceType" hide-details :items="dashboardItems" class="ml-5 pt-0"></v-select>
          </v-flex> -->
          <!-- <v-checkbox label="Validated" v-model="validation"></v-checkbox> -->
          <div class="flex-grow-1"></div>
          <v-text-field xs12 :color="siteColor" v-model="search" label="Search" single-line hide-details clearable @click:clear="removeSearch"></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="dashboardHeaders"
          :items="filteredSensors"
          :items-per-page="50"
          item-key="id"
          class="elevation-1"
          :search="search"
          :sort-by="sortBy"
          :mobile-breakpoint="0"
          :loading="loading"
        >
          <template v-if="loading" v-slot:loading><span>Loading Sensors...</span></template>
          <template v-else-if="!loading && !sensors.length" v-slot:no-data><span>No Sensors Found</span></template>
          <template v-slot:body="{items}">
            <tbody>
              <template v-for="(item,i) in items">
                <tr @click="routeTo(item.uuid, item)" :key="item.id">
                  <td>
                    <v-icon medium :color="checkStatus(item)">mdi-checkbox-blank-circle</v-icon>
                  </td>
                  <td>
                    <v-edit-dialog
                      :return-value.sync="item.name"
                      @save="editItem(item, 'name', i, $event)"
                      @open="setItem(item)"
                      large
                      :color="siteColor"
                    >
                      <v-text-field
                      :color="siteColor"
                        v-model="item.name"
                        hide-details
                      ></v-text-field>
                      <template v-slot:input>
                        <v-text-field
                        :color="siteColor"
                          v-model="item.name"
                          hide-details
                          :readonly="isReadOnly"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>
                  <!-- <td v-if="!isSuper" class="hidden-xs-only">{{ item.location ? item.location.name : "" }}</td> -->
                  <td v-if="isSuper">
                    <v-select @click.native.stop="" @change="changeLocation({id: item.id, location: item.location ? item.location.id : null})" item-text="name" single-line return-object hide-details :items="locations" v-model="item.location" clearable></v-select>
                  </td>
                  <td v-if="isAdmin" class="hidden-xs-only">{{ item.location ? item.location.name : "" }}</td>
                  <td class="hidden-xs-only" v-if="isSuper"><v-select @click.native.stop="" @change="changeSensorOwner({id: item.id, prop: 'sensorUserId', value: item.user ? item.user.id : null})" item-text="email" single-line return-object hide-details :items="users" v-model="item.user" clearable class="hidden-xs-only"></v-select></td>
                  <td class="hidden-xs-only">
                    <v-chip
                        :color="checkSensorBattery(item.battery)"
                        label
                        dark
                        v-if="item.battery"
                      >{{ item.battery.toFixed(2) }}V</v-chip>
                      <div v-else></div>
                  </td>
                  <td v-if="item.low !== null" class="hidden-xs-only">
                    <v-text-field
                      v-if="userDetails.degreePref === 'Celsius'"
                      hide-details
                      single-line
                      @click.stop="setItem(item)"
                      @change="editItem(item, 'lowC', i, $event)"
                      type="number"
                      :color="item.success ? 'green' : siteColor"
                      v-model="item.lowC"
                      :readonly="isReadOnly"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      hide-details
                      single-line
                      @click.stop="setItem(item)"
                      @change="editItem(item, 'low', i, $event)"
                      type="number"
                      :color="item.success ? 'green' : siteColor"
                      v-model="item.low"
                      :readonly="isReadOnly"
                    ></v-text-field>
                  </td>
                  <td v-else @click.stop="item.low = 30" class="hidden-xs-only">Not Set</td>
                  <td v-if="item.high !== null" class="hidden-xs-only">
                    <v-text-field
                      v-if="userDetails.degreePref === 'Celsius'"
                      hide-details
                      single-line
                      @click.stop="setItem(item)"
                      @change="editItem(item, 'highC', i, $event)"
                      type="number"
                      :color="item.success ? 'green' : siteColor"
                      v-model="item.highC"
                      :readonly="isReadOnly"
                    ></v-text-field>
                    <v-text-field
                      v-else
                      hide-details
                      single-line
                      @click.stop="setItem(item)"
                      @change="editItem(item, 'high', i, $event)"
                      type="number"
                      :color="item.success ? 'green' : siteColor"
                      v-model="item.high"
                      :readonly="isReadOnly"
                    ></v-text-field>
                  </td>
                  <td v-else @click.stop="item.high = 44" class="hidden-xs-only">Not Set</td>
                  <td class="hidden-xs-only">
                    <v-chip
                      :color="siteColor"
                      label
                      dark
                      v-if="item.humidity"
                      >{{ Math.round(item.humidity) }}%</v-chip>
                      <div v-else>NA</div>
                  </td>
                  <!-- <td class="hidden-xs-only">{{ `${item.highTemp} / ${item.lowTemp}`  }}</td> -->
                  <!-- <td class="hidden-xs-only" v-if="item.highTemp || item.lowTemp">
                    <v-chip
                      :color="checkSensorRange(item.highTemp, item.low, item.high)"
                      label
                      dark
                    >{{ `${item.highTemp} &deg;F` }}</v-chip>
                    <v-chip
                      :color="checkSensorRange(item.lowTemp, item.low, item.high)"
                      label
                      dark
                    >{{ `${item.lowTemp} &deg;F` }}</v-chip>
                  </td>
                  <td v-else>NA</td> -->
                  <!-- <td v-if="item.duration !== null" class="hidden-xs-only">
                    <v-text-field
                      hide-details
                      single-line
                      @click.stop="setItem(item)"
                      @change="editItem(item, 'duration', i, $event)"
                      type="number"
                      min="10"
                      max="60"
                      step="10"
                      :color="item.success ? 'green' : siteColor"
                      v-model="item.duration"
                    ></v-text-field>
                  </td>
                  <td v-else @click.stop="item.duration = 30" class="hidden-xs-only">Not Set</td> -->
                  <template v-if="item.lastCheckIn">
                    <td class="hidden-xs-only">{{ lastUpdated(item.lastCheckIn) }}</td>
                    <!-- <td class="d-flex align-center" v-if="item.displayValues === 2">
                      <v-chip
                        class="mr-1"
                        :color="checkSensorRange(item.currentTemp, item.low, item.high)"
                        label
                        dark
                      >{{ item.currentTemp }}</v-chip>
                      <v-chip
                        :color="checkSensorRange(item.currentTemp, item.low, item.high)"
                        label
                        dark
                      >{{ item.currentTemp2 }}</v-chip>
                    </td>-->
                    <td v-if="item.currentTemp !== null">
                      <v-chip
                          v-if="userDetails.degreePref === 'Celsius'"
                          :color="checkSensorRange(item.currentTempFull, convertFahrenheitToCelsius(item.low), convertFahrenheitToCelsius(item.high))"
                          label
                          dark
                        >{{ `${roundCelsius(item)} &deg;C` }}
                      </v-chip>
                      <v-chip
                        v-else
                          :color="checkSensorRange(item.currentTemp, item.low, item.high)"
                          label
                          dark
                        >{{ `${item.currentTemp} &deg;F` }}
                      </v-chip>
                    </td>
                    <td v-else>NA</td>
                    <!-- <td>
                    <v-chip color="green" label dark>{{ item.reading.am }}</v-chip>
                    </td>-->
                  </template>
                  <template v-else>
                    <td class="text-left" colspan="2">NA</td>
                  </template>
                </tr>
              </template>
            </tbody>
          </template>
          <!-- <template v-slot:body="{items}" v-else-if="deviceType === 'MDX'">
            <tbody>
              <template v-for="(item,i) in items">
                <tr @click="routeTo(item.uuid, item)" :key="item.id">
                  <td>
                    <v-icon medium :color="item.online ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
                  </td>
                  <td>
                    <v-text-field
                      hide-details
                      single-line
                      :color="item.success ? 'green' : siteColor"
                      @click.stop="setItem(item)"
                      @change="editItem(item, 'name', i, $event)"
                      v-model="item.name"
                    ></v-text-field>
                  </td>
                  <td>{{ item.lastCheckIn ? lastUpdated(item.lastCheckIn) : "NA" }}</td>
                  <td>
                    <v-chip color="green" label dark>{{ checkProperty("t1", item) }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ checkProperty("t2", item) }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ checkProperty("t3", item) }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ checkProperty("t4", item) }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.p1 ? item.p1 : "NA" }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.p2 ? item.p2 : "NA" }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.p3 ? item.p3 : "NA" }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.p4 ? item.p4 : "NA" }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.p5 ? item.p5 : "NA" }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.p6 ? item.p6 : "NA" }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.v1 ? item.v1 : "NA" }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.v2 ? item.v2 : "NA" }}</v-chip>
                  </td>
                  <td>
                    <v-chip color="green" label dark>{{ item.v3 ? item.v3 : "NA" }}</v-chip>
                  </td>
                </tr>
              </template>
            </tbody>
          </template> -->
        </v-data-table>
      </v-card>
    </div>
  </template>
  
  <script>
  import { mapState, mapGetters, mapMutations } from "vuex";
  export default {
    data() {
      return {
        needsCleanUp: true,
        search: "",
        validation: false,
        currentItem: "",
        deviceType: "TDX",
        checkedSensors: false,
        kdxHeaders: [
          {
            text: "Status",
            value: "online",
            width: "8%",
            sortable: false,
            class: "th-header",
          },
          {
            text: "Name",
            value: "name",
            // align: "start",
          },
          {
            text: "Last Update",
            value: "lastCheckIn",
            class: "hidden-xs-only",
          },
          { text: "B1", value: "b1Temp" },
          { text: "B2", value: "b2Temp" },
          { text: "B3", value: "b3Temp" },
          { text: "B4", value: "b4Temp" },
          { text: "C Suction", value: "compSuctionTemp" },
          { text: "C Return", value: "compReturnTemp" },
          { text: "Ambient", value: "ambient" },
        ],
        mdxHeaders: [
          {
            text: "Status",
            value: "online",
            width: "8%",
            sortable: false,
            class: "th-header",
          },
          {
            text: "Name",
            value: "name",
            // align: "start",
          },
          {
            text: "Last Update",
            value: "lastCheckIn",
            class: "hidden-xs-only",
          },
          { text: "T1", value: "t1" },
          { text: "T2", value: "t2" },
          { text: "T3", value: "t3" },
          { text: "T4", value: "t4" },
          { text: "P1", value: "p1" },
          { text: "P2", value: "p2" },
          { text: "P3", value: "p3" },
          { text: "P4", value: "p4" },
          { text: "P5", value: "p5" },
          { text: "P6", value: "p6" },
          { text: "V1", value: "v1" },
          { text: "V2", value: "v2" },
          { text: "V3", value: "v3" },
        ],
        adminHeaders: [
          {
            text: "Status",
            value: "online",
            // width: "8%",
            sortable: true,
            class: "th-header",
          },
          {
            text: "Name",
            value: "name",
            align: "start",
          },
          {
            text: "Location",
            value: "location.name",
            class: "hidden-xs-only",
          },
          {
            text: "Battery",
            value: "battery",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm Low (℉)",
            value: "low",
            width: "10%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm High (℉)",
            value: "high",
            width: "10%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Humidity",
            value: "humidity",
            // width: "10%",
            class: "hidden-xs-only",
          },
          {
            text: "Last Update",
            value: "lastCheckIn",
            // width: "20%",
            class: "hidden-xs-only",
          },
          { 
            text: "Temp", 
            value: "currentTemp", 
            // width: "10%" 
          },
          // { text: "Ambient", value: "reading.am" }
        ],
        adminHeadersCelsius: [
          {
            text: "Status",
            value: "online",
            // width: "8%",
            sortable: true,
            class: "th-header",
          },
          {
            text: "Name",
            value: "name",
            align: "start",
          },
          {
            text: "Location",
            value: "location.name",
            class: "hidden-xs-only",
          },
          {
            text: "Battery",
            value: "battery",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm Low (°C)",
            value: "lowC",
            width: "10%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm High (°C)",
            value: "highC",
            width: "10%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Humidity",
            value: "humidity",
            // width: "10%",
            class: "hidden-xs-only",
          },
          {
            text: "Last Update",
            value: "lastCheckIn",
            // width: "20%",
            class: "hidden-xs-only",
          },
          { 
            text: "Temp", 
            value: "currentTemp", 
            // width: "10%" 
          },
          // { text: "Ambient", value: "reading.am" }
        ],
        tdxHeaders: [
          {
            text: "Status",
            value: "online",
            width: "8%",
            sortable: false,
            class: "th-header",
          },
          {
            text: "Name",
            value: "name",
            align: "start",
          },
          {
            text: "Battery",
            value: "battery",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm Low (℉)",
            value: "low",
            width: "10%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm High (℉)",
            value: "high",
            width: "10%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Humidity",
            value: "humidity",
            width: "10%",
            class: "hidden-xs-only",
          },
          // {
          //   text: "24 Hr High/Low",
          //   value: "",
          //   // width: "10%",
          //   class: "hidden-xs-only",
          // },
          {
            text: "Last Update",
            value: "lastCheckIn",
            width: "20%",
            class: "hidden-xs-only",
          },
          { text: "Temp", value: "currentTemp", width: "10%" },
          // { text: "Ambient", value: "reading.am" }
        ],
        tdxHeadersCelsius: [
          {
            text: "Status",
            value: "online",
            width: "8%",
            sortable: false,
            class: "th-header",
          },
          {
            text: "Name",
            value: "name",
            align: "start",
          },
          {
            text: "Battery",
            value: "battery",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm Low (°C)",
            value: "lowC",
            width: "10%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm High (°C)",
            value: "highC",
            width: "10%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Humidity",
            value: "humidity",
            width: "10%",
            class: "hidden-xs-only",
          },
          // {
          //   text: "24 Hr High/Low",
          //   value: "",
          //   // width: "10%",
          //   class: "hidden-xs-only",
          // },
          {
            text: "Last Update",
            value: "lastCheckIn",
            width: "20%",
            class: "hidden-xs-only",
          },
          { text: "Temp", value: "currentTemp", width: "10%" },
          // { text: "Ambient", value: "reading.am" }
        ],
        superHeaders: [
          {
            text: "Status",
            value: "online",
            // width: "8%",
            sortable: true,
            class: "th-header",
          },
          {
            text: "Name",
            value: "name",
            // width: "20%",
            align: "start",
          },
          {
            text: "Location",
            value: "location.name",
            // class: "hidden-xs-only",
            // width: "14%"
          },
          {
            text: "Owner",
            value: "user.email",
            class: "hidden-xs-only",
            // width: "19%"
          },
          {
            text: "Battery",
            value: "battery",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm Low (℉)",
            value: "low",
            // width: "8%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm High (℉)",
            value: "high",
            // width: "8%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Humidity",
            value: "humidity",
            class: "hidden-xs-only",
            sortable: false,
          },
          // {
          //   text: "24 Hr High/Low",
          //   width: "10%",
          //   class: "hidden-xs-only",
          // },
          {
            text: "Last Update",
            value: "lastCheckIn",
            // width: "20%",
            class: "hidden-xs-only",
          },
          { text: "Temp", 
            value: "currentTemp", 
            // width: "10%" 
          },
          // { text: "Ambient", value: "reading.am" }
        ],
        superHeadersCelsius: [
          {
            text: "Status",
            value: "online",
            // width: "8%",
            sortable: true,
            class: "th-header",
          },
          {
            text: "Name",
            value: "name",
            // width: "20%",
            align: "start",
          },
          {
            text: "Location",
            value: "location.name",
            // class: "hidden-xs-only",
            // width: "14%"
          },
          {
            text: "Owner",
            value: "user.email",
            class: "hidden-xs-only",
            // width: "19%"
          },
          {
            text: "Battery",
            value: "battery",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm Low (°C)",
            value: "lowC",
            // width: "8%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Alarm High (°C)",
            value: "highC",
            // width: "8%",
            class: "hidden-xs-only",
            sortable: false,
          },
          {
            text: "Humidity",
            value: "humidity",
            class: "hidden-xs-only",
            sortable: false,
          },
          // {
          //   text: "24 Hr High/Low",
          //   width: "10%",
          //   class: "hidden-xs-only",
          // },
          {
            text: "Last Update",
            value: "lastCheckIn",
            // width: "20%",
            class: "hidden-xs-only",
          },
          { text: "Temp", 
            value: "currentTemp", 
            // width: "10%" 
          },
          // { text: "Ambient", value: "reading.am" }
        ],
        distributorHeaders: [
          {
            text: "Label",
            value: "label",
          },
          {
            text: "Store",
            value: "store.name",
          },
          {
            text: "Location",
            value: "location.name",
          },
          {
            text: "Last Update",
            value: "reading.createdAt",
          },
          { text: "B1", value: "reading.b1Temp" },
          { text: "B2", value: "reading.b2Temp" },
          { text: "B3", value: "reading.b3Temp" },
          { text: "B4", value: "reading.b4Temp" },
          { text: "C Suction", value: "reading.compSuctionTemp" },
          { text: "C Return", value: "reading.compReturnTemp" },
          { text: "Ambient", value: "reading.ambient" },
        ],
        storeHeaders: [
          {
            text: "Label",
            value: "label",
          },
          {
            text: "Store",
            value: "store.name",
          },
          {
            text: "Location",
            value: "location.name",
          },
          {
            text: "Last Update",
            value: "reading.createdAt",
          },
          { text: "B1", value: "reading.b1Temp" },
          { text: "B2", value: "reading.b2Temp" },
          { text: "B3", value: "reading.b3Temp" },
          { text: "B4", value: "reading.b4Temp" },
          { text: "C Suction", value: "reading.compSuctionTemp" },
          { text: "C Return", value: "reading.compReturnTemp" },
          { text: "Ambient", value: "reading.ambient" },
        ],
        userHeaders: [
          {
            text: "Label",
            value: "label",
          },
          {
            text: "Location",
            value: "location.name",
          },
          {
            text: "Last Update",
            value: "reading.createdAt",
          },
          { text: "B1", value: "reading.b1Temp" },
          { text: "B2", value: "reading.b2Temp" },
          { text: "B3", value: "reading.b3Temp" },
          { text: "B4", value: "reading.b4Temp" },
          { text: "C Suction", value: "reading.compSuctionTemp" },
          { text: "C Return", value: "reading.compReturnTemp" },
          { text: "Ambient", value: "reading.ambient" },
        ],
      };
    },
    async created() {
      await this.$store.dispatch("user/getAllUserDevices", "7c245189-eddf-43dd-9733-cc43ae28d9ea");
      this.$store.commit(
        "sensor/setSensors",
        await this.$store.dispatch("sensor/formatCustomerSensors", this.userDetails.sensors.items)
      );
      if (this.userSearch) this.search = this.userSearch
      this.checkedSensors = true;
      Promise.all([this.$store.dispatch("sensor/allSensorsSubscription"), this.$store.dispatch("sensor/newSensorSubscription", this.user.attributes)])
    },
    computed: {
      ...mapState("sensor", ["sensors"]),
      ...mapState("user", ["user", "userDetails", "userSearch"]),
      ...mapState("location", ["locations"]),
      ...mapState("user", ["users"]),
      ...mapGetters("user", [
        "isSuper",
        "isDistributor",
        "isStore",
        "isUser",
        "isCustomer",
        "isLocation",
        "isTester",
        "isAdmin",
        "isMDX",
        "isKDX",
        "isTDX",
        "isFuelCell",
        "userDistributorName",
        "userStoreName",
        "userId"
      ]),
      ...mapGetters("sensor", ["kdxSensors", "mdxSensors", "tdxSensors"]),
      ...mapGetters("location", ["locationNames"]),
      // ...mapMutations("sensor", ["addAlarm"]),
      loading() {
        if (!this.checkedSensors) return this.siteColor
        else return false
      },
      sortBy() {
        if (this.userDetails.store) return "location.name"
        else return "name"
      },
      isReadOnly() {
        if (this.isLocation) return true
        else return false
      },
      dashboardHeaders() {
        if ((this.isSuper || this.isTester) && this.deviceType === "TDX") {
          if (this.userDetails.degreePref === "Celsius") return this.superHeadersCelsius
          else return this.superHeaders
        }
        if (this.deviceType === "MDX") return this.mdxHeaders;
        else if (this.deviceType === "TDX") {
          if (this.isAdmin) {
            if (this.userDetails.degreePref === "Celsius") return this.adminHeadersCelsius
            else return this.adminHeaders
          }
          else {
            if (this.userDetails.degreePref === "Celsius") return this.tdxHeadersCelsius
            else return this.tdxHeaders
          }
        }
        else if (this.deviceType === "KDX") return this.kdxHeaders;
        else {
          if (this.isSuper) {
            if (this.userDetails.degreePref === "Celsius") return this.superHeadersCelsius
            else return this.superHeaders
          }
          else if (this.isDistributor) return this.distributorHeaders;
          else if (this.isStore) return this.storeHeaders;
          else return this.userHeaders;
        }
      },
      dashboardItems() {
        return this.userDistributorName === "tdxdemo"
          ? ["TDX"]
          : ["TDX", "MDX", "KDX"];
      },
      filteredSensors() {
        if (this.validation) return this.sensors.filter(item => item.validated === false)
        else return this.sensors.filter(item => item.validated !== false)
      }
    },
    methods: {
      convertFahrenheitToCelsius(temp) {
        return ((temp - 32) * 5/9).toFixed(this.userDetails.resolution ? this.userDetails.resolution : 0) 
      },
      cToF(temp) {
        return ((temp * 9/5) + 32).toFixed(0)
      },
      fToC(temp) {
        return ((temp - 32) * 5/9).toFixed(0)
      },
      roundCelsius(item) {
        if (item.currentTempFull === 0) return item.currentTempFull
        else return item.currentTempFull.toFixed(this.userDetails.resolution ? this.userDetails.resolution : 1)
      },
      checkStatus(item) {
        let {device: { model }, alert, online, leak, alarm } = item
        // if (model === "LWL03A" || model === "LWL02") {
        //   if (leak) return 'red'
        //   else return '#009006'
        // }
        if (alert) return 'red'
        else if (!online || leak) return 'blue'
        else if (alarm) return 'orange'
        else return '#009006'
      },
      async changeLocation(val) {
        await this.$store.dispatch("location/updateSensor", val)
      },
      async changeSensorOwner(val) {
        await this.$store.dispatch("sensor/updateSensorPropertySimple", val)
      },
      removeSearch() {
        if (this.userSearch) this.$store.commit("user/removeSearch")
      },
      setItem(item) {
        this.currentItem = { ...item };
      },
      checkProperty(prop, item) {
        if (item[prop] == null) {
          return "NA";
        } else return item[prop];
      },
      checkEmail(email) {
        if (
          email === "dairyqueen@mail.com" ||
          email === "agordonfsf@rrv.net"
        ) {
          return true;
        } else return false;
      },
      async editItem(item, prop, i, event) {
        // if (this.isMDX || this.isKDX || this.isTDX) return;
        let result;
        if (event === "name") {
          result = await this.$store.dispatch("sensor/updateSensorProperty", {
            id: item.id,
            prop,
            value: event ? event : item.name,
          });
        }
        else if (prop === "low" || prop === "lowC") {
          result = await this.$store.dispatch("sensor/updateSensorPropertyTemps", {
              id: item.id,
              low: prop === "low" ? event : this.cToF(event),
              lowC: prop === "lowC" ? event : this.fToC(event),
              updateAlarms: true
          })
        }
        else if (prop === "high" || prop === "highC") {
          result = await this.$store.dispatch("sensor/updateSensorPropertyTemps", {
              id: item.id,
              high: prop === "high" ? event : this.cToF(event),
              highC: prop === "highC" ? event : this.fToC(event),
              updateAlarms: true
          })
        }
        else {
          result = await this.$store.dispatch("sensor/updateSensorProperty", {
            id: item.id,
            prop,
            value: event ? event : prop === "locationNote" ? item.locationNote : item.name,
            duration: item.duration ? item.duration : "30",
            durationChange: prop === "duration" ? true : false,
          });
        }
  
        if (result) {
          // item.success = true;
          // this.items[i].success = true;
          // item.success = true;
          // setTimeout(() => {
          //   item.success = false;
          // }, 2000);
        } else {
          console.log("error");
        }
      },
      checkSensorRange(val, low, high) {
        if (low === null || high === null) {
          return "green";
        } else if (val >= high || val <= low) {
          return "red";
        } else if (val >= high - 2 || val <= low + 2) {
          return "#d8d300";
        } else return "green";
      },
      checkSensorBattery(val) {
        if (val > 2.75) return "green"
        else if (val > 2.5) return "#d8d300"
        else return "red"
      },
      switchDev(item) {
        this.$store.dispatch("sensor/updateDev", item);
      },
      lastUpdated(date) {
        let fullDate = new Date(date).toLocaleDateString();
        let todayDate = new Date().toLocaleDateString();
        if (fullDate === todayDate) {
          return `${new Date(date).toLocaleTimeString()}`
        }
        else return `${fullDate} - ${new Date(date).toLocaleTimeString()}`;
      },
      subscribe(item) {
        this.$store.dispatch("sensor/addUserSub", item.id);
      },
      grab(val) {
        // console.log(val)
      },
      selectTag(tag) {
        this.search = tag;
      },
      async routeTo(route, item) {
        if (this.search) {
          this.$store.commit("user/setSearch", this.search)
        }
        if (item.duel) {
          this.$router.push(`/device/${route}/${item.duelId}`);
        }
        else {
          await this.cleanup()
          this.$router.push(`/device/${route}`);
        }
      },
      capitalize(word) {
        return word.slice(0, 1).toUpperCase() + word.slice(1);
      },
      customFilter(value, search, item) {
        if (typeof value === "object") {
          value = value.map((item) => item.name).join(" ");
        } else if (typeof value === "number") {
          value = value.toString();
        }
        return (
          value != null &&
          search != null &&
          value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
        );
      },
      async cleanup() {
        await Promise.all([this.$store.dispatch("sensor/unsubscribeSensor"), this.$store.dispatch("sensor/unsubscribeNewSensor")])
        this.needsCleanUp = false
      }
    },
    async beforeDestroy() {
      if (this.needsCleanUp) await this.cleanup()
      // await Promise.all([this.$store.dispatch("sensor/unsubscribeSensor"), this.$store.dispatch("sensor/unsubscribeNewSensor")])
      // await this.$nextTick(() => {
      //   console.log(this.$store.state.sensor)
      // })
      // if (!this.isCustomer) {
      //   this.$store.dispatch("sensor/unsubscribeSensor");
      //   // this.$store.dispatch("sensor/emptyAllSensors");
      // }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .v-data-table td {
    font-size: 16px;
  }
  // .v-select__slot {
  //   .v-input__icon--append{
  //     display: none;
  //   }
  // }
  
  tr {
    cursor: pointer;
    span {
      color: #000;
    }
  }
  table {
    .v-text-field {
      border-color: #ffffff1f;
      padding-top: 0px;
      margin-top: 0px;
    }
  }
  // .th-header {
  //   display: none;
  // }
  </style>
  